import { createStore } from 'vuex'

export default createStore({
  state: {
    changeGoodsInfo: JSON.parse(window.sessionStorage.getItem('GoodsInfo')) || {},
    changeUPGoodsInfo: JSON.parse(window.sessionStorage.getItem('UpGoodsInfo')) || {},
    TeamWorkAc: JSON.parse(window.sessionStorage.getItem('TeamWorkAc')) || {},
    SwiperInfo: JSON.parse(window.sessionStorage.getItem('SwiperInfo')) || {},
    AdInfo: JSON.parse(window.sessionStorage.getItem('ADinfo')) || {},
    buyInfo: JSON.parse(window.sessionStorage.getItem('buyInfo')) || {},
    payInfo: JSON.parse(window.sessionStorage.getItem('payInfo')) || {},
    News: window.sessionStorage.getItem('News') || {},
    ProfessionInfo: JSON.parse(window.sessionStorage.getItem('PressionInfo')) || {},
    BreadInfo: [],
    AsideActiveLi: window.sessionStorage.getItem('AsideActiveLi') || '0',
    UserInfo: {},
    CourseInfo: JSON.parse(window.sessionStorage.getItem('CourseInfo')) || {},
    Token: '' || window.sessionStorage.getItem('authToken'),
    userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')) || {},
    editorConfig: {},
    imgBaseUrl: '',
    videoBaseUrl: '',
    UploadUrl: '',
    BaseInfo: JSON.parse(window.sessionStorage.getItem('BaseInfo')) || {},
    upSeachForm:{},
    goodsSeachForm:{},
    courseSeachForm:{},
    NewsSeachForm:{},
    buySeachForm:{},
    paySeachForm:{},
    ADSeachForm:{},
    cancleorderForm:{},
    BaseSeachForm:{},
    LiveSeachForm:{},
    OrderSeachForm:{},
    TeamSeachForm:{},
    SwiperSeachForm:{},
    ChairmanSeachForm:{},
    ProfessionSeachForm:{},
    authSeachForm:{},
    cancleOrderSeachForm:{}
  },
  mutations: {
    setToken(state, Token) {
      state.Token = Token
    },
    setuserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setGoodsInfo(state, changeGoodsInfo) {
      state.changeGoodsInfo = changeGoodsInfo
    },
    setUPGoodsInfo(state, changeUPGoodsInfo) {
      state.changeUPGoodsInfo = changeUPGoodsInfo
    },
    setTeamWorkAc(state, TeamWorkAc) {
      state.TeamWorkAc = TeamWorkAc
    },
    setSwiperInfo(state, SwiperInfo) {
      state.SwiperInfo = SwiperInfo
    },
    setAdInfo(state, AdInfo) {
      state.AdInfo = AdInfo
    },
    setbuyInfo(state, buyInfo) {
      state.buyInfo = buyInfo
    },
    setpayInfo(state, payInfo) {
      state.payInfo = payInfo
    },
    setNews(state, News) {
      state.News = News
    },
    setProfessionInfo(state, ProfessionInfo) {
      state.ProfessionInfo = ProfessionInfo
    },
    setCourseInfo(state, CourseInfo) {
      state.CourseInfo = CourseInfo
    },
    setBreadInfo(state, BreadInfo) {
      state.BreadInfo = BreadInfo
    },
    setUserInfo(state, UserInfo) {
      state.UserInfo = UserInfo
    },
    setAsideActiveLi(state, AsideActiveLi) {
      state.AsideActiveLi = AsideActiveLi
    },
    seteditorConfig(state, editorConfig) {
      state.editorConfig = editorConfig
    },
    setimgBaseUrl(state, imgBaseUrl) {
      state.imgBaseUrl = imgBaseUrl
    },
    setvideoBaseUrl(state, videoBaseUrl) {
      state.videoBaseUrl = videoBaseUrl
    },
    setBaseInfo(state, BaseInfo) {
      state.BaseInfo = BaseInfo
    },
    setupSeachForm(state, upSeachForm) {
      state.upSeachForm = upSeachForm
    },
    setgoodsSeachForm(state, goodsSeachForm) {
      state.goodsSeachForm = goodsSeachForm
    },
    setcourseSeachForm(state, courseSeachForm) {
      state.courseSeachForm = courseSeachForm
    },
    setNewsSeachForm(state, NewsSeachForm) {
      state.NewsSeachForm = NewsSeachForm
    },
    setbuySeachForm(state, buySeachForm) {
      state.buySeachForm = buySeachForm
    },
    setpaySeachForm(state, paySeachForm) {
      state.paySeachForm = paySeachForm
    },
    setADSeachForm(state, ADSeachForm) {
      state.ADSeachForm = ADSeachForm
    },
    setBaseSeachForm(state, BaseSeachForm) {
      state.BaseSeachForm = BaseSeachForm
    },
    setLiveSeachForm(state, LiveSeachForm) {
      state.LiveSeachForm = LiveSeachForm
    },
    setOrderSeachForm(state, OrderSeachForm) {
      state.OrderSeachForm = OrderSeachForm
    },
    setTeamSeachForm(state, TeamSeachForm) {
      state.TeamSeachForm = TeamSeachForm
    },
    setSwiperSeachForm(state, SwiperSeachForm) {
      state.SwiperSeachForm = SwiperSeachForm
    },
    setChairmanSeachForm(state, ChairmanSeachForm) {
      state.ChairmanSeachForm = ChairmanSeachForm
    },
    setProfessionSeachForm(state, ProfessionSeachForm) {
      state.ProfessionSeachForm = ProfessionSeachForm
    },
    setauthSeachForm(state, authSeachForm) {
      state.authSeachForm = authSeachForm
    },
    setcancleOrderSeachForm(state, cancleOrderSeachForm) {
      state.cancleOrderSeachForm = cancleOrderSeachForm
    },
    setUploadUrl(state, UploadUrl) {
      state.UploadUrl = UploadUrl
    },
  },
  actions: {
  },
  modules: {
  }
})
